import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import { purple, green, spURL_NoInputJSON, spURL, orange } from "../../App";
import { FooterButton, MenuButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";
import { convertToCurrency } from "../../functions/ConvertToCurrency";
import { ConvertDateFormat } from "../../functions/ConvertDateFormat";
import { CapitaliseString } from "../../functions/CapitaliseString";
import { PopupScheduleQuote } from "./PopupScheduleQuote";
import { PopupResolveQuote } from "./PopupResolveQuote";

export const Quote = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");
  const [storename, setStoreName] = store.useState("storename");
  const [NonStockInvoiceResponse, setNonStockInvoiceResponse] = useState("");

  const emptyQuoteDetails = {
    QuoteID: 0,
    QuoteNo: "",
    NonStockSupplierID: 0,
    NonStockSupplierName: "",
    Amount: 0,
    DateCreated: "",
    DateScheduled: "",
    Comments: "",
    StoreNo: "",
    DateInvoiced: "",
  };
  const [QuoteDetails, setQuoteDetails] = useState(emptyQuoteDetails);

  const refQuoteNo = useRef();
  const refSupplierSearch = useRef();
  const refNonStockSupplierName = useRef();
  const refNonStockSupplierNameNew = useRef();
  const refNonStockSupplierNameNewContainer = useRef();
  const refAmount = useRef();
  const refComments = useRef();

  const [ddlNonStockSupplierName, setDDLNonStockSupplierName] = useState([]);

  const [SelectedNonStockSupplierName, setSelectedNonStockSupplierName] = useState("");

  const [showPopupScheduleQuote, setShowPopupScheduleQuote] = useState(false);
  const [showPopupResolveQuote, setShowPopupResolveQuote] = useState(false);

  const getSupplierNames = async (search) => {
    const resObj = await runFetch(`${spURL}Handheld_NonStock_SupplierNames_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          Search: refSupplierSearch.current.value,
          From: "OTHER",
        }),
      }),
    });

    if (resObj.response === null) {
      setNonStockInvoiceResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        setDDLNonStockSupplierName(spResponse.Table);

        if (
          spResponse.Table.map((supplier) => supplier.SupplierName).includes(search.toUpperCase())
        ) {
          refNonStockSupplierName.current.value = search.toUpperCase();
        } else {
          refNonStockSupplierName.current.value = "";
        }
      } else {
        setDDLNonStockSupplierName([]);
      }
    }
  };

  const handleClick_Save = async () => {
    setNonStockInvoiceResponse("");

    let inputJSON = "";

    switch (props.Type) {
      case "CREATE":
        inputJSON = JSON.stringify({
          StoreNo: storeno,
          NonStockSupplierName:
            SelectedNonStockSupplierName === "NEW"
              ? refNonStockSupplierNameNew.current.value
              : refNonStockSupplierName.current.value,
          QuoteNo: refQuoteNo.current.value,
          Amount: refAmount.current.value,
          Comments: refComments.current.value,
        });
        break;
      case "EDIT":
        inputJSON = JSON.stringify({
          QuoteID: props.SelectedQuoteID,
          NonStockSupplierName:
            SelectedNonStockSupplierName === "NEW"
              ? refNonStockSupplierNameNew.current.value
              : refNonStockSupplierName.current.value,
          QuoteNo: refQuoteNo.current.value,
          Amount: refAmount.current.value,
          Comments: refComments.current.value,
        });
        break;
      default:
        break;
    }

    const resObj = await runFetch(
      `${spURL}Handheld_NonStock_Quote_${CapitaliseString(props.Type)}`,
      {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: inputJSON,
        }),
      }
    );

    if (resObj.response === null) {
      setNonStockInvoiceResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        if (spResponse.Table[0].OutputString !== undefined) {
          props.setPage("quotes");
        }
      }
    }
  };

  const handleChange_NonStockSupplierSearch = async (_term) => {
    if (_term.length >= 3) {
      getSupplierNames(_term);
    } else {
      setDDLNonStockSupplierName([]);
    }
  };

  const handleChange_NonStockSupplierName = (e) => {
    setNonStockInvoiceResponse("");

    const _name = e.target.value;
    setSelectedNonStockSupplierName(_name);
  };

  useEffect(() => {
    if (refNonStockSupplierNameNewContainer.current) {
      if (SelectedNonStockSupplierName === "NEW") {
        refNonStockSupplierNameNewContainer.current.classList.remove("hidden");
      } else {
        refNonStockSupplierNameNewContainer.current.classList.add("hidden");
      }
    }
  }, [SelectedNonStockSupplierName, refNonStockSupplierNameNewContainer.current]);

  const getQuoteDetails = async (_quoteid) => {
    const resObj = await runFetch(`${spURL}Handheld_NonStock_Quote_Details_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          QuoteID: _quoteid,
        }),
      }),
    });

    if (resObj.response === null) {
      setNonStockInvoiceResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setQuoteDetails({
          QuoteID: spResponse.Table[0].QuoteID,
          QuoteNo: spResponse.Table[0].QuoteNo,
          NonStockSupplierID: spResponse.Table[0].NonStockSupplierID,
          NonStockSupplierName: spResponse.Table[0].NonStockSupplierName,
          Amount: spResponse.Table[0].Amount,
          DateCreated: spResponse.Table[0]["Date Created"],
          DateScheduled:
            spResponse.Table[0]["Date Scheduled"] === null
              ? ""
              : spResponse.Table[0]["Date Scheduled"],
          Comments: spResponse.Table[0].Comments,
          StoreNo: spResponse.Table[0].StoreNo,
          DateInvoiced:
            spResponse.Table[0]["Date Invoiced"] === null
              ? ""
              : spResponse.Table[0]["Date Invoiced"],
        });
      } else {
        setQuoteDetails(emptyQuoteDetails);
      }
    }
  };

  const handleClick_Invoice = () => {
    setNonStockInvoiceResponse("");
    setShowPopupResolveQuote(true);
  };

  useEffect(() => {
    if (props.Type === "EDIT" || props.Type === "VIEW") {
      getQuoteDetails(props.SelectedQuoteID);
    }
  }, [showPopupScheduleQuote]);

  useEffect(() => {
    refQuoteNo.current.value = QuoteDetails.QuoteNo;
    refSupplierSearch.current.value = QuoteDetails.NonStockSupplierName;
    refAmount.current.value = QuoteDetails.Amount;
    refComments.current.value = QuoteDetails.Comments;
    getSupplierNames(QuoteDetails.NonStockSupplierName);
  }, [QuoteDetails]);

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <h4>
          <strong>{props.Type} Quote</strong>
        </h4>
        <p className="error-message">{NonStockInvoiceResponse}</p>
        <div className="stocktake-create-input-row">
          <p>
            <strong>Store:</strong>
          </p>
          <p>{storename}</p>
        </div>
        <div className="stocktake-create-input-row">
          <p>
            <strong>Desc:</strong>
          </p>
          <input
            type="text"
            ref={refQuoteNo}
            className={
              (props.Access.NonStockInvoiceAdmin !== 1 || props.Type === "VIEW") && "disabled"
            }
          />
        </div>
        {props.Type === "VIEW" && (
          <>
            <hr />
            <h5>
              <strong>Date</strong>
            </h5>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Scheduled:</strong>
              </p>
              <p>{ConvertDateFormat(QuoteDetails.DateScheduled, "en-CA")}</p>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Invoiced:</strong>
              </p>
              <p>{ConvertDateFormat(QuoteDetails.DateInvoiced, "en-CA")}</p>
            </div>
          </>
        )}

        <hr />
        <h5>
          <strong>Supplier</strong>
        </h5>
        <div className="stocktake-create-input-row">
          <p>
            <strong>Search:</strong>
          </p>
          <input
            type="text"
            ref={refSupplierSearch}
            onChange={(e) => handleChange_NonStockSupplierSearch(e.target.value)}
            className={
              (props.Access.NonStockInvoiceAdmin !== 1 || props.Type === "VIEW") && "disabled"
            }
          />
        </div>
        <div className="stocktake-create-input-row">
          <p>
            <strong>Select:</strong>
          </p>
          <select
            ref={refNonStockSupplierName}
            onChange={handleChange_NonStockSupplierName}
            className={
              (props.Access.NonStockInvoiceAdmin !== 1 || props.Type === "VIEW") && "disabled"
            }
          >
            <option value=""></option>
            {ddlNonStockSupplierName.map((supplier, index) => (
              <option
                value={supplier.SupplierName}
                key={index}
              >
                {supplier.SupplierName}
              </option>
            ))}
            <option value="NEW">New...</option>
          </select>
        </div>
        <div
          className="stocktake-create-input-row hidden"
          ref={refNonStockSupplierNameNewContainer}
        >
          <p>
            <strong>New Supplier:</strong>
          </p>
          <input
            type="text"
            ref={refNonStockSupplierNameNew}
          />
        </div>
        <hr />
        <div className="money-input-row">
          <p>
            <strong>Amount:</strong>
          </p>
          <span
            className={
              (props.Access.NonStockInvoiceAdmin !== 1 || props.Type === "VIEW") && "disabled"
            }
          >
            $
          </span>
          <input
            type="text"
            ref={refAmount}
            className={
              (props.Access.NonStockInvoiceAdmin !== 1 || props.Type === "VIEW") && "disabled"
            }
          />
        </div>
        <div className="stocktake-create-input-row">
          <p>
            <strong>Comment:</strong>
          </p>
          <textarea
            rows={5}
            ref={refComments}
            className={
              (props.Access.NonStockInvoiceAdmin !== 1 || props.Type == "VIEW") && "disabled"
            }
          ></textarea>
        </div>
        <br />
        {props.Access.NonStockInvoiceAdmin === 1 && props.Type === "EDIT" && (
          <div className="blockedorders-option-row">
            <MenuButton
              title="Invoice"
              colour={orange}
              button="I"
              onClick={handleClick_Invoice}
            />
          </div>
        )}

        <PopupScheduleQuote
          show={showPopupScheduleQuote}
          setShow={setShowPopupScheduleQuote}
          QuoteDetails={QuoteDetails}
          setNonStockInvoiceResponse={setNonStockInvoiceResponse}
        />

        <PopupResolveQuote
          show={showPopupResolveQuote}
          setShow={setShowPopupResolveQuote}
          QuoteDetails={QuoteDetails}
          setPage={props.setPage}
          getQuotes={props.getQuotes}
        />
      </div>

      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={orange}
          onClick={() => {
            props.setPage("quotes");
          }}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={orange}
          />
        </Link>
        {props.Type === "EDIT" && (
          <FooterButton
            button="Book"
            colour={orange}
            onClick={() => {
              setNonStockInvoiceResponse("");
              setShowPopupScheduleQuote(true);
            }}
          />
        )}
        {props.Access.NonStockInvoiceAdmin === 1 && props.Type !== "VIEW" && (
          <FooterButton
            button="Save"
            colour={orange}
            onClick={handleClick_Save}
          />
        )}
      </div>
    </div>
  );
};
