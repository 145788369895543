import { BlobServiceClient } from "@azure/storage-blob";
import React, { useState, useEffect, useRef } from "react";
import { FileUploader } from "react-drag-drop-files";
import { Link } from "react-router-dom";
import { green, spURL, spURL_NoInputJSON, store } from "../../App";
import { FooterButton, MenuButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";
import imageCompression from "browser-image-compression";
import { trackPromise } from "react-promise-tracker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ConvertDateFormat } from "../../functions/ConvertDateFormat";

export const ViewIssue = (props) => {
  const [StoreAuditToolResponse, setStoreAuditToolResponse] = useState("");

  const emptyIssueDetails = {
    IssueID: 0,
    IssueSubject: "",
    IssueDescription: "",
    IssueImageURL: "",
    AssignedTo: "",
    Resolved: 0,
    ResolveComment: "",
    DateCreated: "",
    DateResolved: "",
  };
  const [IssueDetails, setIssueDetails] = useState(emptyIssueDetails);

  const emptyIssueImage = {
    ImageLineNo: 0,
    ImageURL: "",
  };
  const [IssueImages, setIssueImages] = useState(emptyIssueImage);

  const handleClick_Back = () => {
    props.setStoreAuditToolResponse("");
    props.setSelectedIssueID("");
    props.setPage("issue-list");
  };

  const getIssueDetails = async () => {
    const resObj = await runFetch(`${spURL}Handheld_StoreAuditTool_IssueDetails_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          IssueID: props.SelectedIssueID,
        }),
      }),
    });

    if (resObj.response === null) {
      setStoreAuditToolResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setIssueDetails({
          IssueID: spResponse.Table[0].ID,
          IssueSubject: spResponse.Table[0].IssueSubject,
          IssueDescription: spResponse.Table[0].IssueDescription,
          IssueImageURL: spResponse.Table[0].IssueImageURL,
          AssignedTo: spResponse.Table[0].AssignedTo,
          DueDate: spResponse.Table[0]["Due Date"],
          Resolved: spResponse.Table[0].Resolved,
          ResolveComment: spResponse.Table[0].ResolveComment,
          DateCreated: spResponse.Table[0]["Date Created"],
          DateResolved: spResponse.Table[0]["Date Resolved"],
        });
      } else {
        setIssueDetails(emptyIssueDetails);
      }

      if (spResponse.Table1 !== undefined && spResponse.Table1.length > 0) {
        setIssueImages({
          ImageLineNo: spResponse.Table1[0].ImageLineNo,
          ImageURL: spResponse.Table1[0].ImageURL,
        });
      } else {
        setIssueImages(emptyIssueImage);
      }
    }
  };

  useEffect(() => {
    getIssueDetails();
  }, []);

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div className="stocktake-create-input-row">
          <p>
            <strong>IssueID:</strong>
          </p>
          <p>{IssueDetails.IssueID}</p>
        </div>
        <div className="stocktake-create-input-row">
          <p>
            <strong>Created on:</strong>
          </p>
          <p>{new Date(IssueDetails.DateCreated).toLocaleString()}</p>
        </div>
        <div className="stocktake-create-input-row">
          <p>
            <strong>Due on:</strong>
          </p>
          <p>{ConvertDateFormat(IssueDetails.DueDate, "en-CA")}</p>
        </div>
        <div className="stocktake-create-input-row">
          <p>
            <strong>Resolved on:</strong>
          </p>
          <p>{new Date(IssueDetails.DateResolved).toLocaleString()}</p>
        </div>
        <div className="stocktake-create-input-row">
          <p>
            <strong>Subject:</strong>
          </p>
          <p>{IssueDetails.IssueSubject}</p>
        </div>
        <div className="stocktake-create-input-row">
          <p>
            <strong>Description:</strong>
          </p>
          <textarea
            rows="10"
            disabled
            placeholder="Enter issue description here..."
            value={IssueDetails.IssueDescription}
          ></textarea>
        </div>
        <div className="stocktake-create-input-row">
          <p>
            <strong>Assigned to:</strong>
          </p>
          <p>{IssueDetails.AssignedTo}</p>
        </div>
        <div className="stocktake-create-input-row">
          <p>
            <strong>Resolve Comment:</strong>
          </p>
          <textarea
            rows="10"
            disabled
            placeholder="Enter resolve comment here..."
          ></textarea>
        </div>
        {IssueDetails.IssueImageURL !== "" && (
          <>
            <p>
              <strong>Sample Image</strong>
            </p>
            <div className="blockedorders-option-row">
              <div className="storeaudittool-fileupload-container">
                <img
                  className="storeaudittool-image"
                  src={IssueDetails.IssueImageURL}
                />
              </div>
            </div>
          </>
        )}
        {IssueImages.LineNo !== 0 && (
          <>
            <p>
              <strong>Resolved Image</strong>
            </p>
            <div className="blockedorders-option-row">
              <div className="storeaudittool-fileupload-container">
                <img
                  className="storeaudittool-image"
                  src={IssueImages.ImageURL}
                />
              </div>
            </div>
          </>
        )}

        <p className="error-message">{StoreAuditToolResponse}</p>
      </div>

      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={green}
          onClick={handleClick_Back}
        />
        <FooterButton
          button="Home"
          colour={green}
          onClick={props.handleClick_Home}
        />
      </div>
    </div>
  );
};
