import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";

import { spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders, spURL_LocalPOS } from "../../App";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";
import { ConvertDateFormat } from "../../functions/ConvertDateFormat";

export const PopupResolveQuote = (props) => {
  const [storeno, ,] = store.useState("storeno");

  const [PopupResponse, setPopupResponse] = useState("");

  const refInvoiceNo = useRef();
  const refDateInvoiced = useRef();
  const refAmount = useRef();
  const refComments = useRef();

  const handleClose = () => {
    setPopupResponse("");
    cleanPopup();
    props.setShow(false);
  };

  const handleShow = async () => {
    setPopupResponse("");
    refAmount.current.value = props.QuoteDetails.Amount;
    refComments.current.value = props.QuoteDetails.Comments;
    refDateInvoiced.current.value = ConvertDateFormat(new Date(), "en-CA");
    refInvoiceNo.current.focus();
  };

  const cleanPopup = () => {
    refInvoiceNo.current.value = "";
    refDateInvoiced.current.value = "";
    refAmount.current.value = "";
    refComments.current.value = "";
  };

  const handleClick_Create = async () => {
    const resObj = await runFetch(`${spURL}Handheld_NonStock_Invoice_Create`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: storeno,
          InvoiceNo: refInvoiceNo.current.value,
          NonStockSupplierName: props.QuoteDetails.NonStockSupplierName,
          Amount: refAmount.current.value,
          QuoteID: props.QuoteDetails.QuoteID,
          DateInvoiced: refDateInvoiced.current.value,
          Comments: refComments.current.value,
        }),
      }),
    });

    if (resObj.response === null) {
      setPopupResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        props.setPage("quotes");
        props.getQuotes();
      }
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onShow={handleShow}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <h4>
              <strong>Convert Quote to Invoice</strong>
            </h4>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Quote:</strong>
              </p>
              <p>{props.QuoteDetails.QuoteNo}</p>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>InvoiceNo:</strong>
              </p>
              <input
                type="text"
                ref={refInvoiceNo}
              />
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Supplier:</strong>
              </p>
              <p>{props.QuoteDetails.NonStockSupplierName}</p>
            </div>
            <div className="money-input-row">
              <p>
                <strong>Amount:</strong>
              </p>
              <span className="dollar-sign">$</span>
              <input
                type="text"
                ref={refAmount}
              />
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Invoiced on:</strong>
              </p>
              <input
                type="date"
                required
                ref={refDateInvoiced}
              />
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Comments</strong>
              </p>
              <textarea
                rows={5}
                ref={refComments}
              ></textarea>
            </div>
            <p className="error-message">{PopupResponse}</p>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              <strong>Close</strong>
            </button>
            <button
              className="popup-button"
              onClick={handleClick_Create}
            >
              <strong>Convert</strong>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
