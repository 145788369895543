import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import "./fonts/Password/password.ttf";
import { HashRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import { Homepage, checkDeviceAuth, checkAccess, VerifyPage, LockPage } from "./App";
import reportWebVitals from "./reportWebVitals";
import { APILoadingComponent } from "./components/APILoadingComponent";
import { Header } from "./components/Header";
import { useIdleTimer } from "react-idle-timer";
import { PODCodeBody } from "./pages/PODCode/PODCode";
import { ItemDetailsBody } from "./pages/ItemDetails/ItemDetails";
import { MerchandisingBody } from "./pages/Merchandising/Merchandising";
import { WHReceivedBody } from "./pages/WHReceived/WHReceived";
import { StockTakeBody } from "./pages/StockTake/StockTake";
import { ECommBody } from "./pages/EComm";
import { StoreTransferBody } from "./pages/StoreTransfer/StoreTransfer";
import { BlockedOrdersBody } from "./pages/BlockedOrders/BlockedOrders";
import { FailedOrdersBody } from "./pages/FailedOrders/FailedOrders";
import { ResendOrdersBody } from "./pages/ResendOrders/ResendOrders";
import { CustomerServiceBody } from "./pages/CustomerService/CustomerService";
import { UnallocatedOrdersBody } from "./pages/UnallocatedOrders/UnallocatedOrders";
import { LabelsBody } from "./pages/Labels/Labels";
import { ItemDetailsHOBody } from "./pages/ItemDetailsHO/ItemDetailsHO";
import { PhoneOrdersBody } from "./pages/PhoneOrders/PhoneOrders";
import { WebOrdersBody } from "./pages/WebOrders/WebOrders";
import { ITBody } from "./pages/IT/IT";
import { SearchTermManageBody } from "./pages/SearchTermManage/SearchTermManage";
import { WebBufferBody } from "./pages/WebBuffer/WebBuffer";
import { IncomingDeliveriesBody } from "./pages/IncomingDeliveries/IncomingDeliveries";
import { MarketingBody } from "./pages/Marketing";
import { CompetitionDrawBody } from "./pages/CompetitionDraw/CompetitionDraw";
import { SmythsManageBody } from "./pages/SmythsManage/SmythsManage";
import { BudgetBody } from "./pages/Budget/Budget";
import { POSMessageBody } from "./pages/POSMessage/POSMessageMain";
import { PrintReceiptBody } from "./pages/PrintReceipt/PrintReceipt";
import { AllocateBody } from "./pages/Allocate/Allocate";
import { UnallocateBody } from "./pages/Allocate/Unallocate";
import { DeviceManagementStatusBody } from "./pages/DeviceManagementStatus";
import { StaffManageBody } from "./pages/StaffManage/StaffManage";
import { PerthWarehouseBody } from "./pages/PerthWarehouse/PerthWarehouse";
import { WebsiteCMSBody } from "./pages/WebsiteCMS/WebsiteCMS";
import { MarketingGiftCardCreateBody } from "./pages/MarketingGiftCardCreate/MarketingGiftCardCreate";
import { BulkyItemMerchBody } from "./pages/BulkyItemMerch/BulkyItemMerch";
import { SSCCSplitBody } from "./pages/SSCCSplit/SSCCSplit";
import { CategoryReportManageBody } from "./pages/CategoryReportManage/CategoryReportManage";
import { CageAllocationBody } from "./pages/CageAllocation/CageAllocation";
import { ReportsBody } from "./pages/Reports/Reports";
import { StoreAuditToolBody } from "./pages/StoreAuditTool/StoreAuditTool";
import store from "./store";
import { CheckListBody } from "./pages/CheckList/CheckList";
import { StoreComplianceToolBody } from "./pages/StoreComplianceTool/StoreComplianceTool";
import { NonStockInvoiceBody } from "./pages/NonStockInvoice/NonStockInvoice";

store.setState("storeno", "");
store.setState("storename", "");
store.setState("userid", "");
store.setState("username", "");
store.setState("ip", "");
store.setState("issignedin", "0");
store.setState("session", "");

const IdleTimeout = (doLogout) => {
  const onPrompt = () => {
    // Fire a Modal Prompt
  };

  const onIdle = () => {
    // Close Modal Prompt
    // Do some idle action like log out your user
    doLogout();
  };

  const onActive = (event) => {
    // Close Modal Prompt
    // Do some active action
  };

  const onAction = (event) => {
    // Do something when a user triggers a watched event
  };

  const {
    start,
    reset,
    pause,
    resume,
    isIdle,
    isPrompted,
    isLeader,
    getRemainingTime,
    getElapsedTime,
    getLastIdleTime,
    getLastActiveTime,
    getTotalIdleTime,
    getTotalActiveTime,
  } = useIdleTimer({
    onPrompt,
    onIdle,
    onActive,
    onAction,
    timeout: 1000 * 60 * 30, // milliseconds, ie 1000 * 60 * 5 = 5 mins
    promptTimeout: 0,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    emitOnAllTabs: false,
  });
};

const Page = (props) => {
  const [storeno, setStoreNo] = store.useState('storeno');
  const [storename, setStoreName] = store.useState("storename");
  const [session, setSession] = store.useState("session");
  const [userid, setUserID] = store.useState('userid');
  const [username, setUserName] = store.useState('username');
  const [isSignedIn, setIsSignedIn] = store.useState('issignedin');
  const [ip, setIP] = store.useState('ip');
  const navigate = useNavigate();

  let strPageTitleNoSpace = props.PageTitle.replaceAll(" ", "");

  const doLogout = () => {
    setIsSignedIn('0');
    setStoreNo("");
    setStoreName("");
    setUserID("");
    setUserName("");
    setIP("");
    navigate("/");
  }

  IdleTimeout(doLogout);

  checkAccess(strPageTitleNoSpace, userid, setIsSignedIn);
  checkDeviceAuth(strPageTitleNoSpace, ip, userid, setSession);

  const renderPage = () => {
    if (session === "Logged") {
      return (
        <div className="main-container">
          <Header page={`${props.PageTitle}${props.WithStoreName ? ` - ${storename}` : ""}`} doLogout={doLogout} />
          {props.PageComponent}
        </div>
      );
    } else if (session === null) {
      navigate("/");
    } else if (session === "Verify") {
      return <VerifyPage />;
    } else if (session.substring(0, 4) === "Lock") {
      return <LockPage />;
    }
  };

  return (
    <>
      <APILoadingComponent />
      {renderPage()}
    </>
  );
};

export const arrPageRoutes = [
  { Page: "Item Details", Component: <ItemDetailsBody />, Env: "STORE" },
  { Page: "Merchandising", Component: <MerchandisingBody />, Env: "STORE" },
  { Page: "Bulky Item Merch", Component: <BulkyItemMerchBody />, Env: "STORE" },
  { Page: "POD Code", Component: <PODCodeBody />, Env: "STORE" },
  { Page: "WH Received", Component: <WHReceivedBody />, Env: "STORE" },
  { Page: "Store Transfer", Component: <StoreTransferBody />, Env: "STORE" },
  { Page: "Stock Take", Component: <StockTakeBody />, Env: "STORE" },
  { Page: "EComm", Component: <ECommBody />, Env: "HO" },
  { Page: "Blocked Orders", Component: <BlockedOrdersBody />, Env: "HO" },
  { Page: "Failed Orders", Component: <FailedOrdersBody />, Env: "HO" },
  { Page: "Resend Orders", Component: <ResendOrdersBody />, Env: "HO" },
  { Page: "Customer Service", Component: <CustomerServiceBody />, Env: "HO" },
  { Page: "Unallocated Orders", Component: <UnallocatedOrdersBody />, Env: "HO" },
  { Page: "Labels", Component: <LabelsBody />, Env: "STORE" },
  { Page: "Item Details HO", Component: <ItemDetailsHOBody />, Env: "HO" },
  { Page: "Phone Orders", Component: <PhoneOrdersBody />, Env: "HO" },
  { Page: "Web Orders", Component: <WebOrdersBody />, Env: "HO" },
  { Page: "IT", Component: <ITBody />, Env: "HO" },
  { Page: "Search Term Manage", Component: <SearchTermManageBody />, Env: "HO" },
  { Page: "Web Buffer", Component: <WebBufferBody />, Env: "HO" },
  { Page: "Incoming Deliveries", Component: <IncomingDeliveriesBody />, Env: "STORE" },
  { Page: "Marketing", Component: <MarketingBody />, Env: "HO" },
  { Page: "Competition Draw", Component: <CompetitionDrawBody />, Env: "HO" },
  { Page: "Smyths Manage", Component: <SmythsManageBody />, Env: "HO" },
  { Page: "Budget", Component: <BudgetBody />, Env: "STORE" },
  { Page: "POS Message", Component: <POSMessageBody />, Env: "HO" },
  { Page: "Print Receipt", Component: <PrintReceiptBody />, Env: "HO" },
  { Page: "Allocate", Component: <AllocateBody />, Env: "STORE" },
  { Page: "Unallocate", Component: <UnallocateBody />, Env: "STORE" },
  { Page: "Device Status", Component: <DeviceManagementStatusBody />, Env: "STORE" },
  { Page: "Staff Manage", Component: <StaffManageBody />, Env: "STORE" },
  { Page: "Perth Warehouse", Component: <PerthWarehouseBody />, Env: "HO" },
  { Page: "Marketing Gift Card Create", Component: <MarketingGiftCardCreateBody />, Env: "HO" },
  { Page: "Website CMS", Component: <WebsiteCMSBody />, Env: "HO" },
  { Page: "SSCC Split", Component: <SSCCSplitBody />, Env: "STORE" },
  { Page: "Category Report Manage", Component: <CategoryReportManageBody />, Env: "HO" },
  { Page: "Cage Allocation", Component: <CageAllocationBody />, Env: "STORE" },
  { Page: "Reports", Component: <ReportsBody />, Env: "HO" },
  { Page: "Store Audit Tool", Component: <StoreAuditToolBody />, Env: "STORE" },
  { Page: "Check List", Component: <CheckListBody />, Env: "STORE" },
  { Page: "Store Compliance", Component: <StoreComplianceToolBody />, Env: "STORE" },
  { Page: "Non Stock Invoice", Component: <NonStockInvoiceBody />, Env: "STORE" }
];

const root = createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route
          path="/"
          element={<Homepage />}
        />
        {arrPageRoutes.map((item, index) => {
          let pageTitle = item.Page;
          let pageLink = item.Page.replaceAll(" ", "");
          return (
            <Route
              key={index}
              path={`/${pageLink}`}
              element={
                <Page
                  WithStoreName={item.Env === "STORE"}
                  PageTitle={pageTitle}
                  PageComponent={item.Component}
                />
              }
            />
          );
        })}
      </Routes>
    </Router>
  </React.StrictMode>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
