import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";

import { spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders, spURL_LocalPOS } from "../../App";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";
import { ConvertDateFormat } from "../../functions/ConvertDateFormat";

export const PopupConfirmDeleteQuote = (props) => {
  const [PopupResponse, setPopupResponse] = useState("");

  const handleClose = () => {
    setPopupResponse("");
    props.setShow(false);
  };

  const handleShow = () => {
    setPopupResponse("");
  };

  const handleClick_No = () => {
    handleClose();
  };

  const handleClick_Yes = async () => {
    const resObj = await runFetch(`${spURL}Handheld_NonStock_Quote_Delete`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          QuoteID: props.SelectedQuote.QuoteID,
        }),
      }),
    });

    if (resObj.response === null) {
      setPopupResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        props.setNonStockInvoiceResponse(spResponse.Table[0].OutputString);
        props.getQuotes();
        handleClose();
      }
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onShow={handleShow}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <p>
              <strong>Confirm delete quote {props.SelectedQuote.QuoteNo}?</strong>
            </p>
            <p className="error-message">{PopupResponse}</p>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClick_No}
            >
              <strong>No</strong>
            </button>
            <button
              className="popup-button"
              onClick={handleClick_Yes}
            >
              <strong>Yes</strong>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
