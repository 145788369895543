import React, { Suspense, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { Barcode } from "./Barcode";
import { Details } from "./Details";
import { SOO } from "./SOO";
import { SOT } from "./SOT";
import { SOH } from "./SOH";
import { spURL, spURL_GetIP, spURL_Local, spURL_LocalPOS } from "../../App";
import { trackPromise } from "react-promise-tracker";
import store from "../../store";
import { runFetch } from "../../functions/RunFetch";

export const ItemDetailsBody = () => {
  const [storeno, setStoreNo] = store.useState("storeno");

  const [from, setFrom] = useState("");

  const [page, setPage] = useState("barcode");

  const [ItemDetailsResponse, setItemDetailsResponse] = useState("");

  const [data, setData] = useState({
    CanNotBeMarkDownBelow: "",
    Category: "",
    Department: "",
    Description: "",
    ExtendedDescription: "",
    ImageURL: "",
    ItemNo: "",
    ItemSource: "",
    ItemType: "",
    MSRP: "",
    MarkDownDate: "",
    MarkDownDateOK: "",
    NextMarkDownDate: "",
    Price: "",
    RangeStatus: "",
    ReorderNumber: "",
    SOH: "",
    SOHWH: "",
    SOO: "",
    SOT: "",
    Sales0d: "",
    Sales7d: "",
    Sales28d: "",
    SubCategory: "",
    Supplier: "",
    WebURL: "",
    LastReceiptDate: "",
    LastDateModified: "",
  });

  const [edmTable, setEDMTable] = useState("");

  const [searchTerm, setSearchTerm] = useState("");
  const [index, setIndex] = useState(0);

  const getItemDetails_Local = async (barcode) => {
    setItemDetailsResponse("");

    const res = await runFetch(`${spURL_LocalPOS}Handheld_ItemDetail_Item_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          Barcode: barcode,
          StoreNo: storeno,
        }),
      }),
    });

    const resObj = JSON.parse(res);

    if (resObj.status === "ERROR!") {
      setItemDetailsResponse(resObj.exception);
    } else if (resObj.status === "OK!") {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        setData((values) => ({
          ...values,
          Category: spResponse.Table[0].Category,
          Department: spResponse.Table[0].Department,
          Description: spResponse.Table[0].Description,
          ExtendedDescription: spResponse.Table[0].ExtendedDescription,
          ImageURL: spResponse.Table[0].ImageURL,
          ItemNo: spResponse.Table[0].ItemNo,
          ItemSource: spResponse.Table[0].ItemSource,
          ItemType: spResponse.Table[0].ItemType,
          MSRP: spResponse.Table[0].MSRP,
          Price: spResponse.Table[0].Price,
          RangeStatus: spResponse.Table[0].RangeStatus,
          ReorderNumber: spResponse.Table[0].ReorderNumber,
          SubCategory: spResponse.Table[0].SubCategory,
          Supplier: spResponse.Table[0].Supplier,
          WebURL: spResponse.Table[0].WebURL,
        }));
      }
      if (spResponse.Table1.length > 0) {
        setEDMTable(
          spResponse.Table1.map((item, index) => (
            <tr key={index}>
              <td>{item.Promotion}</td>
            </tr>
          ))
        );
      }

      setPage("details");
    }
  };

  const exeBarcodeClick = async (barcode) => {
    if (process.env.REACT_APP_ENV === "STORE") {
      getItemDetails_Local(barcode);
    }
    setItemDetailsResponse("");

    const resObj = await runFetch(`${spURL}Handheld_ItemDetail_Item_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          Barcode: barcode,
          StoreNo: storeno,
        }),
      }),
    });

    if (resObj.response === null) {
      setItemDetailsResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        if (process.env.REACT_APP_ENV === "STORE") {
          setData((values) => ({
            ...values,
            CanNotBeMarkDownBelow: spResponse.Table[0].CanNotBeMarkDownBelow,
            MarkDownDate: spResponse.Table[0].MarkDownDate,
            MarkDownDateOK: spResponse.Table[0].MarkDownDateOK,
            NextMarkDownDate: spResponse.Table[0].NextMarkDownDate,
            SOH: spResponse.Table[0].SOH,
            SOHWH: spResponse.Table[0].SOHWH,
            SOO: spResponse.Table[0].SOO,
            SOT: spResponse.Table[0].SOT,
            Sales0d: spResponse.Table[0].Sales0d,
            Sales7d: spResponse.Table[0].Sales7d,
            Sales28d: spResponse.Table[0].Sales28d,
            LastReceiptDate: spResponse.Table[0].LastReceiptDate,
          }));
        } else {
          setData((values) => ({
            ...values,
            CanNotBeMarkDownBelow: spResponse.Table[0].CanNotBeMarkDownBelow,
            MarkDownDate: spResponse.Table[0].MarkDownDate,
            MarkDownDateOK: spResponse.Table[0].MarkDownDateOK,
            NextMarkDownDate: spResponse.Table[0].NextMarkDownDate,
            SOH: spResponse.Table[0].SOH,
            SOHWH: spResponse.Table[0].SOHWH,
            SOO: spResponse.Table[0].SOO,
            SOT: spResponse.Table[0].SOT,
            Sales0d: spResponse.Table[0].Sales0d,
            Sales7d: spResponse.Table[0].Sales7d,
            Sales28d: spResponse.Table[0].Sales28d,
            LastReceiptDate: spResponse.Table[0].LastReceiptDate,
            Category: spResponse.Table[0].Category,
            Department: spResponse.Table[0].Department,
            Description: spResponse.Table[0].Description,
            ExtendedDescription: spResponse.Table[0].ExtendedDescription,
            ImageURL: spResponse.Table[0].ImageURL,
            ItemNo: spResponse.Table[0].ItemNo,
            ItemSource: spResponse.Table[0].ItemSource,
            ItemType: spResponse.Table[0].ItemType,
            MSRP: spResponse.Table[0].MSRP,
            Price: spResponse.Table[0].Price,
            RangeStatus: spResponse.Table[0].RangeStatus,
            ReorderNumber: spResponse.Table[0].ReorderNumber,
            SubCategory: spResponse.Table[0].SubCategory,
            Supplier: spResponse.Table[0].Supplier,
            WebURL: spResponse.Table[0].WebURL,
          }));
        }
      }

      if (spResponse.Table1.length > 0) {
        setEDMTable(
          spResponse.Table1.map((item, index) => (
            <tr key={index}>
              <td>{item.Promotion}</td>
            </tr>
          ))
        );
      }

      setPage("details");
    }
  };

  const barcodePage = (
    <Barcode
      exeBarcodeClick={exeBarcodeClick}
      ItemDetailsResponse={ItemDetailsResponse}
      setItemDetailsResponse={setItemDetailsResponse}
    />
  );

  const detailsPage = (
    <Details
      data={data}
      page={page}
      setPage={setPage}
      edmTable={edmTable}
      setEDMTable={setEDMTable}
      ItemDetailsResponse={ItemDetailsResponse}
      setItemDetailsResponse={setItemDetailsResponse}
      from={from}
      searchTerm={searchTerm}
      index={index}
    />
  );

  const sooPage = (
    <SOO
      page={page}
      itemno={data.ItemNo}
      setPage={setPage}
      setItemDetailsResponse={setItemDetailsResponse}
      ItemDetailsResponse={ItemDetailsResponse}
    />
  );

  const sotPage = (
    <SOT
      page={page}
      itemno={data.ItemNo}
      setPage={setPage}
      setItemDetailsResponse={setItemDetailsResponse}
      ItemDetailsResponse={ItemDetailsResponse}
    />
  );

  const sohPage = (
    <SOH
      page={page}
      itemno={data.ItemNo}
      setPage={setPage}
      setItemDetailsResponse={setItemDetailsResponse}
      ItemDetailsResponse={ItemDetailsResponse}
    />
  );

  const getCurrentPage = (page) => {
    var currentPage;

    switch (page) {
      case "barcode":
        currentPage = barcodePage;
        break;
      case "details":
        currentPage = detailsPage;
        break;
      case "soo":
        currentPage = sooPage;
        break;
      case "sot":
        currentPage = sotPage;
        break;
      case "soh":
        currentPage = sohPage;
        break;
      default:
        currentPage = "";
        break;
    }

    return currentPage;
  };

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    if (searchParams !== null) {
      const params = new URLSearchParams(searchParams);

      const _from = params.get("From") === null ? "" : params.get("From").replace("   ", " + ");

      setFrom(_from);

      const _itemno =
        params.get("ItemNo") === null ? "" : params.get("ItemNo").replace("   ", " + ");

      const _searchterm =
        params.get("SearchTerm") === null ? "" : params.get("SearchTerm").replace("   ", " + ");

      setSearchTerm(_searchterm);

      const _index = params.get("Index") === null ? "" : params.get("Index").replace(" ", " + ");

      setIndex(_index);

      if (_from === "Labels") {
        const _response = decodeURIComponent(params.get("Response"));

        document.getElementById("itembarcode-barcode").value = _itemno;
        setItemDetailsResponse(_response);

        exeBarcodeClick(_itemno);
        setPage("details");
      } else if (_from === "ItemSearch") {
        document.getElementById("itembarcode-barcode").value = _itemno;
        exeBarcodeClick(_itemno);
        setPage("details");
      }
    }
  }, []);

  return getCurrentPage(page);
};
