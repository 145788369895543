import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import { purple, green, spURL_NoInputJSON, spURL, orange } from "../../App";
import { ContentButton, FooterButton, MenuButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";
import { convertToCurrency } from "../../functions/ConvertToCurrency";
import { ConvertDateFormat } from "../../functions/ConvertDateFormat";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { PopupConfirmDeleteQuote } from "./PopupConfirmDeleteQuote";

export const Quotes = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");
  const [storename, setStoreName] = store.useState("storename");
  const [NonStockInvoiceResponse, setNonStockInvoiceResponse] = useState("");
  const [Quotes, setQuotes] = useState([]);
  const [Filter, setFilter] = useState("NEW");

  const emptySelectedQuote = {
    QuoteID: 0,
    QuoteNo: "",
  };
  const [SelectedQuote, setSelectedQuote] = useState(emptySelectedQuote);

  const [showConfirmDeleteQuote, setShowConfirmDeleteQuote] = useState(false);

  const handleClick_Create = () => {
    setNonStockInvoiceResponse("");
    props.setPage("quote-create");
  };

  const getQuotes = async () => {
    const resObj = await runFetch(`${spURL}Handheld_NonStock_Quotes_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: storeno,
        }),
      }),
    });

    if (resObj.response === null) {
      setNonStockInvoiceResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        setQuotes(spResponse.Table);
      }
    }
  };

  useEffect(() => {
    getQuotes();
  }, []);

  return (
    <div className="page-container">
      <div className="desktop-body-container">
        <p>
          <strong>Store: </strong>
          {storename}
        </p>
        <h4>
          <strong>Quotes</strong>
        </h4>
        <div className="nonstock-top-row-container">
          <div className="nonstock-option-row">
            <ContentButton
              button=""
              colour="white"
              id="weborders-pickfilter-new"
              onClick={() => setFilter("NEW")}
            />
            <ContentButton
              button=""
              colour="lightgreen"
              id="weborders-pickfilter-completed"
              onClick={() => setFilter("DONE")}
            />
          </div>
        </div>
        <table className="itemdetails-table">
          <thead>
            <tr>
              <th>Desc</th>
              <th>Supplier</th>
              <th>Booked</th>
            </tr>
          </thead>
          <tbody>
            {Quotes.filter((quote) => quote.QuoteType === Filter).map((quote, index) => (
              <tr
                key={index}
                className={`${
                  quote.Status === "BOOKED" || quote.QuoteType === "DONE"
                    ? "background-lightgreen"
                    : quote.Status === "LATE"
                    ? "background-salmon"
                    : ""
                }`}
              >
                <td>{quote.QuoteNo}</td>
                <td>{quote.NonStockSupplierName}</td>
                <td>
                  {quote["Date Scheduled"] === null
                    ? ""
                    : ConvertDateFormat(quote["Date Scheduled"], "en-CA")}
                </td>
                {quote.QuoteType === "NEW" && (
                  <td>
                    <FontAwesomeIcon
                      className="btnHover"
                      icon={solid("pen-to-square")}
                      onClick={() => {
                        setNonStockInvoiceResponse("");
                        props.setSelectedQuoteID(quote.QuoteID);
                        props.setPage(`quote-edit`);
                      }}
                    />
                    {props.Access.NonStockInvoiceAdmin === 1 && (
                      <FontAwesomeIcon
                        className="btnHover color-red"
                        icon={solid("xmark")}
                        onClick={() => {
                          setNonStockInvoiceResponse("");
                          setSelectedQuote({ QuoteID: quote.QuoteID, QuoteNo: quote.QuoteNo });
                          setShowConfirmDeleteQuote(true);
                        }}
                      />
                    )}
                  </td>
                )}
                {quote.QuoteType === "DONE" && (
                  <th>
                    <FontAwesomeIcon
                      className="btnHover"
                      icon={solid("eye")}
                      onClick={() => {
                        setNonStockInvoiceResponse("");
                        props.setSelectedQuoteID(quote.QuoteID);
                        props.setPage(`quote-view`);
                      }}
                    />
                  </th>
                )}
              </tr>
            ))}
          </tbody>
        </table>
        <p className="error-message">{NonStockInvoiceResponse}</p>

        <PopupConfirmDeleteQuote
          show={showConfirmDeleteQuote}
          setShow={setShowConfirmDeleteQuote}
          SelectedQuote={SelectedQuote}
          setNonStockInvoiceResponse={setNonStockInvoiceResponse}
          getQuotes={getQuotes}
        />
      </div>

      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={orange}
          onClick={() => {
            props.setPage("menu");
          }}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={orange}
          />
        </Link>
        {props.Access.NonStockInvoiceAdmin === 1 && (
          <FooterButton
            button="Create"
            colour={orange}
            onClick={handleClick_Create}
          />
        )}
      </div>
    </div>
  );
};
