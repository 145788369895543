import { BlobServiceClient } from "@azure/storage-blob";
import React, { useState, useEffect, useRef } from "react";
import { FileUploader } from "react-drag-drop-files";
import { Link } from "react-router-dom";
import { green, spURL, spURL_NoInputJSON, store } from "../../App";
import { FooterButton, MenuButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";
import imageCompression from "browser-image-compression";
import { trackPromise } from "react-promise-tracker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PopupCameraScreen } from "./PopupCameraScreen";
import { ConvertDateFormat } from "../../functions/ConvertDateFormat";

export const EditIssueMobile = (props) => {
  const [StoreAuditToolResponse, setStoreAuditToolResponse] = useState("");
  const [IsImageModified, setIsImageModified] = useState(false);

  const blobServiceClient = new BlobServiceClient(
    `https://tmportalimages.blob.core.windows.net/?sv=2022-11-02&ss=bfqt&srt=co&sp=rwdlacupiytfx&se=2050-12-31T13:00:00Z&st=2024-07-10T00:26:54Z&spr=https&sig=ahho9vjnyCei7eSyP5PBNmT3ppPfQajEw10jdi4qD1A%3D`
  ); // create a blobServiceClient
  const containerClient = blobServiceClient.getContainerClient("handheldstoreaudittoolimages"); // create a containerClient

  const emptyIssueDetails = {
    IssueID: 0,
    IssueSubject: "",
    IssueDescription: "",
    IssueImageURL: "",
    AssignedTo: "",
    Resolved: 0,
    ResolveComment: "",
    DateCreated: "",
    DateResolved: "",
    DueDate: "",
  };
  const [IssueDetails, setIssueDetails] = useState(emptyIssueDetails);
  const emptyIssueImage = {
    ImageLineNo: 0,
    ImageURL: "",
  };
  const [IssueImages, setIssueImages] = useState(emptyIssueImage);

  const emptyUploadedImageFile = {
    blob: "",
  };
  const [UploadedImageFile, setUploadedImageFile] = useState(emptyUploadedImageFile);

  const [showCamera, setShowCamera] = useState(false);

  const refSubject = useRef();
  const refDescription = useRef();
  const refAssignedTo = useRef();
  const refDueDate = useRef();
  const refResolveComment = useRef();

  const handleClick_Back = () => {
    props.setStoreAuditToolResponse("");
    props.setSelectedIssueID("");
    props.setPage("issue-list");
  };

  const getIssueDetails = async () => {
    const resObj = await runFetch(`${spURL}Handheld_StoreAuditTool_IssueDetails_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          IssueID: props.SelectedIssueID,
        }),
      }),
    });

    if (resObj.response === null) {
      setStoreAuditToolResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setIssueDetails({
          IssueID: spResponse.Table[0].ID,
          IssueSubject: spResponse.Table[0].IssueSubject,
          IssueDescription: spResponse.Table[0].IssueDescription,
          IssueImageURL: spResponse.Table[0].IssueImageURL,
          AssignedTo: spResponse.Table[0].AssignedTo,
          Resolved: spResponse.Table[0].Resolved,
          ResolveComment: spResponse.Table[0].ResolveComment,
          DateCreated: spResponse.Table[0]["Date Created"],
          DateResolved: spResponse.Table[0]["Date Resolved"],
          DueDate: ConvertDateFormat(spResponse.Table[0]["Due Date"], "en-CA"),
        });

        if (refSubject.current) {
          refSubject.current.value = spResponse.Table[0].IssueSubject;
        }
        refDescription.current.value = spResponse.Table[0].IssueDescription;
        if (refAssignedTo.current) {
          refAssignedTo.current.value = spResponse.Table[0].AssignedTo;
        }
        refResolveComment.current.value = spResponse.Table[0]["Resolved Comment"];
        if (refDueDate.current) {
          refDueDate.current.value = ConvertDateFormat(spResponse.Table[0]["Due Date"], "en-CA");
        }
      } else {
        setIssueDetails(emptyIssueDetails);
      }

      if (spResponse.Table1 !== undefined && spResponse.Table1.length > 0) {
        setIssueImages({
          ImageLineNo: spResponse.Table1[0].ImageLineNo,
          ImageURL: spResponse.Table1[0].ImageURL,
        });
      } else {
        setIssueImages(emptyIssueImage);
      }
    }
  };

  const handleClick_Resolve = async () => {
    const resObj = await runFetch(`${spURL}Handheld_StoreAuditTool_Issue_Resolve`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          IssueID: IssueDetails.IssueID,
          ResolveComment: refResolveComment.current.value,
        }),
      }),
    });

    if (resObj.response === null) {
      setStoreAuditToolResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setStoreAuditToolResponse(spResponse.Table[0].OutputString);
        props.setPage("issue-list");
      }
    }
  };

  const handleClick_Save = async () => {
    // upload image if we wanted to
    if (IsImageModified) {
      if (UploadedImageFile.blob === "") {
        handleClick_DeleteImage();
      } else {
        const blobURL = `https://tmportalimages.blob.core.windows.net/handheldstoreaudittoolimages/${encodeURIComponent(
          `${props.SelectedProject.ProjectID}_${props.SelectedIssueID}`
        )}.jpg`;

        const blobClient = containerClient.getBlockBlobClient(
          `${props.SelectedProject.ProjectID}_${props.SelectedIssueID}.jpg`
        );

        trackPromise(blobClient.uploadData(UploadedImageFile.blob))
          .then(async () => {
            const resObj = await runFetch(`${spURL}Handheld_StoreAuditTool_IssueImage_Upload`, {
              method: "POST",
              body: new URLSearchParams({
                inputJSON: JSON.stringify({
                  IssueID: props.SelectedIssueID,
                  ImageURL: blobURL,
                }),
              }),
            });

            if (resObj.response === null) {
              setStoreAuditToolResponse(resObj.exception);
            } else {
              editIssue();
            }
          })
          .catch((err) => {
            setStoreAuditToolResponse(`ERROR!: Image upload error. ${err}`);
          });
      }
    } else {
      editIssue();
    }
  };

  const editIssue = async () => {
    // update
    const resObj = await trackPromise(
      runFetch(`${spURL}Handheld_StoreAuditTool_Issue_Edit`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            IssueID: props.SelectedIssueID,
            IssueSubject: refSubject.current ? refSubject.current.value : IssueDetails.IssueSubject,
            IssueDescription: refDescription.current.value,
            AssignedTo: refAssignedTo.current
              ? refAssignedTo.current.value
              : IssueDetails.AssignedTo,
            ResolveComment: refResolveComment.current.value,
            DueDate: refDueDate.current ? refDueDate.current.value : IssueDetails.DueDate,
          }),
        }),
      })
    );

    if (resObj.response === null) {
      setStoreAuditToolResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setStoreAuditToolResponse(spResponse.Table[0].OutputString);
        props.setPage("issue-list");
      }
    }
  };

  const processSnappedImage = async (imageString) => {
    await trackPromise(
      fetch(imageString)
        .then((data) => data.blob())
        .then((imgBlob) => {
          setUploadedImageFile({ blob: imgBlob });
          setIsImageModified(true);
        })
    );
  };

  const handleClick_Delete = async () => {
    const resObj = await runFetch(`${spURL}Handheld_StoreAuditTool_Issue_Delete`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          IssueID: IssueDetails.IssueID,
        }),
      }),
    });

    if (resObj.response === null) {
      setStoreAuditToolResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setStoreAuditToolResponse(spResponse.Table[0].OutputString);
      }
    }
  };

  const handleClick_DeleteImage = async () => {
    const resObj = await runFetch(`${spURL}Handheld_StoreAuditTool_IssueImage_Delete`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          IssueID: IssueDetails.IssueID,
        }),
      }),
    });

    if (resObj.response === null) {
      setStoreAuditToolResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setStoreAuditToolResponse(spResponse.Table[0].OutputString);
        props.setPage("issue-list");
      }
    }
  };

  useEffect(() => {
    getIssueDetails();
  }, []);

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        {props.Access.StoreAuditToolAdmin ? (
          <>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Subject:</strong>
              </p>
              <input
                type="text"
                ref={refSubject}
              />
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Description:</strong>
              </p>
              <textarea
                ref={refDescription}
                rows="10"
                placeholder="Enter issue description here..."
              ></textarea>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Assigned to:</strong>
              </p>
              <input
                type="text"
                ref={refAssignedTo}
              />
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Due:</strong>
              </p>
              <input
                type="date"
                ref={refDueDate}
              />
            </div>
          </>
        ) : (
          <>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Subject:</strong>
              </p>
              <p>{IssueDetails.IssueSubject}</p>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Description:</strong>
              </p>
              <textarea
                ref={refDescription}
                rows="10"
                placeholder="Enter issue description here..."
                disabled
              ></textarea>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Assigned to:</strong>
              </p>
              <p>{IssueDetails.AssignedTo}</p>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Due:</strong>
              </p>
              <p>{ConvertDateFormat(IssueDetails.DueDate, "en-CA")}</p>
            </div>
          </>
        )}
        <div className="stocktake-create-input-row">
          <p>
            <strong>Resolve Comment:</strong>
          </p>
          <textarea
            ref={refResolveComment}
            rows="10"
            placeholder="Enter resolve comment here..."
          ></textarea>
        </div>
        {IssueDetails.IssueImageURL !== "" && (
          <>
            <p>
              <strong>Sample Image</strong>
            </p>
            <div className="blockedorders-option-row">
              <div className="storeaudittool-fileupload-container">
                <img
                  className="storeaudittool-image"
                  src={IssueDetails.IssueImageURL}
                />
              </div>
            </div>
          </>
        )}
        <p>
          <strong>Resolved Image</strong>
        </p>
        <div className="blockedorders-option-row">
          <div className="storeaudittool-fileupload-container">
            {IsImageModified ? (
              UploadedImageFile.blob === "" ? (
                <MenuButton
                  className="no-highlight"
                  button="UP"
                  colour={green}
                  title="Upload Photo"
                  onClick={() => setShowCamera(true)}
                />
              ) : (
                <>
                  <img
                    className="storeaudittool-image btnHover"
                    src={URL.createObjectURL(UploadedImageFile.blob)}
                    onClick={() => setShowCamera(true)}
                  />
                  <FontAwesomeIcon
                    className="storeaudittool-deleteimage-icon btnHover"
                    icon="fas fa-times"
                    onClick={() => {
                      setIsImageModified(true);
                      setUploadedImageFile(emptyUploadedImageFile);
                    }}
                  />
                </>
              )
            ) : IssueImages.ImageLineNo === 0 ? (
              <MenuButton
                button="UP"
                colour={green}
                title="Upload Photo"
                onClick={() => setShowCamera(true)}
              />
            ) : (
              <>
                <img
                  className="storeaudittool-image btnHover"
                  src={IssueImages.ImageURL}
                  onClick={() => setShowCamera(true)}
                />
                <FontAwesomeIcon
                  className="storeaudittool-deleteimage-icon btnHover"
                  icon="fas fa-times"
                  onClick={() => {
                    setIsImageModified(true);
                    setUploadedImageFile(emptyUploadedImageFile);
                  }}
                />
              </>
            )}
          </div>
        </div>

        <p className="error-message">{StoreAuditToolResponse}</p>

        <PopupCameraScreen
          show={showCamera}
          setShow={setShowCamera}
          processSnappedImage={processSnappedImage}
        />
      </div>

      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={green}
          onClick={handleClick_Back}
        />
        <FooterButton
          button="Home"
          colour={green}
          onClick={props.handleClick_Home}
        />
        {props.Access.StoreAuditToolAdmin && (
          <>
            <FooterButton
              button="Delete"
              colour={green}
              onClick={handleClick_Delete}
            />
            <FooterButton
              button="Rslve"
              colour={green}
              onClick={handleClick_Resolve}
            />
          </>
        )}
        <FooterButton
          button="Save"
          colour={green}
          onClick={handleClick_Save}
        />
      </div>
    </div>
  );
};
