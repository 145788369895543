import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import { purple, green, spURL_NoInputJSON, spURL, orange } from "../../App";
import { FooterButton, MenuButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";
import { convertToCurrency } from "../../functions/ConvertToCurrency";
import { ConvertDateFormat } from "../../functions/ConvertDateFormat";
import { CapitaliseString } from "../../functions/CapitaliseString";

export const Invoice = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");
  const [storename, setStoreName] = store.useState("storename");
  const [NonStockInvoiceResponse, setNonStockInvoiceResponse] = useState("");

  const emptyInvoiceDetails = {
    InvoiceID: 0,
    InvoiceNo: "",
    NonStockSupplierID: 0,
    NonStockSupplierName: "",
    Amount: 0,
    QuoteID: 0,
    DateInvoiced: "",
    DateCreated: "",
    DateExported: "",
    Comments: "",
    StoreNo: "",
  };
  const [InvoiceDetails, setInvoiceDetails] = useState(emptyInvoiceDetails);

  const refInvoiceNo = useRef();
  const refNonStockSupplierName = useRef();
  const refAmount = useRef();
  const refDateInvoiced = useRef();
  const refComments = useRef();

  const [ddlNonStockSupplierName, setDDLNonStockSupplierName] = useState([]);

  const getSupplierNames = async (_supplier) => {
    const resObj = await runFetch(`${spURL}Handheld_NonStock_SupplierNames_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          Search: "",
          From: InvoiceDetails.QuoteID === 0 || InvoiceDetails.QuoteID === null ? "MAIN" : "OTHER",
        }),
      }),
    });

    if (resObj.response === null) {
      setNonStockInvoiceResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        setDDLNonStockSupplierName(spResponse.Table);

        if (
          spResponse.Table.map((supplier) => supplier.SupplierName).includes(
            _supplier.toUpperCase()
          )
        ) {
          refNonStockSupplierName.current.value = _supplier.toUpperCase();
        } else {
          if (refNonStockSupplierName.current) {
            refNonStockSupplierName.current.value = "";
          }
        }
      } else {
        setDDLNonStockSupplierName([]);
      }
    }
  };

  const handleClick_Save = async () => {
    setNonStockInvoiceResponse("");

    let inputJSON = "";

    switch (props.Type) {
      case "CREATE":
        inputJSON = JSON.stringify({
          StoreNo: storeno,
          InvoiceNo: refInvoiceNo.current.value,
          NonStockSupplierName: refNonStockSupplierName.current.value,
          Amount: refAmount.current.value,
          QuoteID: InvoiceDetails.QuoteID,
          DateInvoiced: refDateInvoiced.current.value,
          Comments: refComments.current.value,
        });
        break;
      case "EDIT":
        inputJSON = JSON.stringify({
          InvoiceID: props.SelectedInvoiceID,
          InvoiceNo: refInvoiceNo.current.value,
          NonStockSupplierName: refNonStockSupplierName.current.value,
          Amount: refAmount.current.value,
          DateInvoiced: refDateInvoiced.current.value,
          Comments: refComments.current.value,
        });
        break;
      default:
        break;
    }

    const resObj = await runFetch(
      `${spURL}Handheld_NonStock_Invoice_${CapitaliseString(props.Type)}`,
      {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: inputJSON,
        }),
      }
    );

    if (resObj.response === null) {
      setNonStockInvoiceResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        if (spResponse.Table[0].OutputString !== undefined) {
          props.setPage("invoices");
        }
      }
    }
  };

  const getInvoiceDetails = async (_invoiceid) => {
    const resObj = await runFetch(`${spURL}Handheld_NonStock_Invoice_Details_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          InvoiceID: _invoiceid,
        }),
      }),
    });

    if (resObj.response === null) {
      setNonStockInvoiceResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setInvoiceDetails({
          InvoiceID: spResponse.Table[0].InvoiceID,
          InvoiceNo: spResponse.Table[0].InvoiceNo,
          NonStockSupplierID: spResponse.Table[0].NonStockSupplierID,
          NonStockSupplierName: spResponse.Table[0].NonStockSupplierName,
          Amount: spResponse.Table[0].Amount,
          QuoteID: spResponse.Table[0].QuoteID,
          DateInvoiced: spResponse.Table[0]["Date Invoiced"],
          DateCreated: spResponse.Table[0]["Date Created"],
          DateExported:
            spResponse.Table[0]["Date Exported"] === null
              ? ""
              : spResponse.Table[0]["Date Exported"],
          Comments: spResponse.Table[0].Comments,
          StoreNo: spResponse.Table[0].StoreNo,
        });
      } else {
        setInvoiceDetails(emptyInvoiceDetails);
      }
    }
  };

  useEffect(() => {
    if (props.Type === "CREATE") {
      getSupplierNames("");
    } else {
      getInvoiceDetails(props.SelectedInvoiceID);
    }
  }, []);

  useEffect(() => {
    if (props.Type !== "CREATE") {
      refInvoiceNo.current.value = InvoiceDetails.InvoiceNo;
      refAmount.current.value = InvoiceDetails.Amount;
      refDateInvoiced.current.value = InvoiceDetails.DateInvoiced;
      refComments.current.value = InvoiceDetails.Comments;

      getSupplierNames(InvoiceDetails.NonStockSupplierName);

      console.log(InvoiceDetails.NonStockSupplierName);
    }
  }, [InvoiceDetails]);

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <h4>
          <strong>{props.Type} Invoice</strong>
        </h4>
        <div className="stocktake-create-input-row">
          <p>
            <strong>Store:</strong>
          </p>
          <p>{storename}</p>
        </div>
        <div className="stocktake-create-input-row">
          <p>
            <strong>InvoiceNo:</strong>
          </p>
          <input
            type="text"
            ref={refInvoiceNo}
            className={props.Type === "VIEW" && "disabled"}
          />
        </div>
        <div className="stocktake-create-input-row">
          <p>
            <strong>Supplier:</strong>
          </p>
          {InvoiceDetails.QuoteID === 0 || InvoiceDetails.QuoteID === null ? (
            <select
              ref={refNonStockSupplierName}
              className={props.Type === "VIEW" && "disabled"}
            >
              <option value=""></option>
              {ddlNonStockSupplierName.map((supplier, index) => (
                <option
                  value={supplier.SupplierName}
                  key={index}
                >
                  {supplier.SupplierName}
                </option>
              ))}
            </select>
          ) : (
            <p>{InvoiceDetails.NonStockSupplierName}</p>
          )}
        </div>
        <div className="money-input-row">
          <p>
            <strong>Amount:</strong>
          </p>
          <span className={props.Type === "VIEW" && "disabled"}>$</span>
          <div className={props.Type === "VIEW" && "disabled"}>
            <input
              type="text"
              ref={refAmount}
            />
          </div>
        </div>
        <div className="stocktake-create-input-row">
          <p>
            <strong>Invoice Date:</strong>
          </p>
          <input
            type="date"
            required
            ref={refDateInvoiced}
            className={props.Type === "VIEW" && "disabled"}
          />
        </div>
        <div className="stocktake-create-input-row">
          <p>
            <strong>Comment:</strong>
          </p>
          <textarea
            rows={5}
            ref={refComments}
            className={props.Type === "VIEW" && "disabled"}
          ></textarea>
        </div>
        <br />
        <p className="error-message">{NonStockInvoiceResponse}</p>
      </div>

      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={orange}
          onClick={() => {
            props.setPage("invoices");
          }}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={orange}
          />
        </Link>
        {props.Type !== "VIEW" && (
          <FooterButton
            button="Save"
            colour={orange}
            onClick={handleClick_Save}
          />
        )}
      </div>
    </div>
  );
};
