import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";

import { spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders, spURL_LocalPOS } from "../../App";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";
import { ConvertDateFormat } from "../../functions/ConvertDateFormat";

export const PopupScheduleQuote = (props) => {
  const [PopupResponse, setPopupResponse] = useState("");

  const refDateScheduled = useRef();

  const handleClose = () => {
    setPopupResponse("");
    cleanPopup();
    props.setShow(false);
  };

  const handleShow = async () => {
    setPopupResponse("");
    refDateScheduled.current.value = ConvertDateFormat(props.QuoteDetails.DateScheduled, "en-CA");
  };

  const cleanPopup = () => {
    refDateScheduled.current.value = "";
  };

  const handleClick_Unbook = async () => {
    const resObj = await runFetch(`${spURL}Handheld_NonStock_Quote_Cancel`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          QuoteID: props.QuoteDetails.QuoteID,
        }),
      }),
    });

    if (resObj.response === null) {
      setPopupResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        props.setNonStockInvoiceResponse(spResponse.Table[0].OutputString);
        handleClose();
      }
    }
  };

  const handleClick_Book = async () => {
    const resObj = await runFetch(`${spURL}Handheld_NonStock_Quote_Schedule`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          QuoteID: props.QuoteDetails.QuoteID,
          DateScheduled: refDateScheduled.current.value,
        }),
      }),
    });

    if (resObj.response === null) {
      setPopupResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        props.setNonStockInvoiceResponse(spResponse.Table[0].OutputString);
        handleClose();
      }
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onShow={handleShow}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <h4>
              <strong>Schedule Quote</strong>
            </h4>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Quote:</strong>
              </p>
              <p>{props.QuoteDetails.QuoteNo}</p>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Scheduled Date:</strong>
              </p>
              <input
                type="date"
                required
                ref={refDateScheduled}
              />
            </div>
            <p className="error-message">{PopupResponse}</p>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              <strong>Close</strong>
            </button>
            <button
              className="popup-button"
              onClick={handleClick_Unbook}
            >
              <strong>Unbook</strong>
            </button>
            <button
              className="popup-button"
              onClick={handleClick_Book}
            >
              <strong>Book</strong>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
