import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import { purple, green, spURL_NoInputJSON, spURL } from "../../App";
import { FooterButton, MenuButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";
import is from "is_js";

export const IssueList = (props) => {
  const [StoreAuditToolResponse, setStoreAuditToolResponse] = useState(
    props.StoreAuditToolResponse
  );
  const [IssueList, setIssueList] = useState([]);
  const [IsOpenOnlyChecked, setIsOpenOnlyChecked] = useState(true);

  const handleClick_OpenOnly = () => {
    setStoreAuditToolResponse("");
    setIsOpenOnlyChecked(refOpenOnly.current.checked);
  };

  const refOpenOnly = useRef();

  const handleClick_Create = () => {
    setStoreAuditToolResponse("");
    props.setPage(`issue-create${is.mobile() ? "-mobile" : ""}`);
  };

  const handleClick_Print = async () => {
    setStoreAuditToolResponse("");
    const resObj = await runFetch(`${spURL}CreateStoreAuditReport`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          ProjectID: props.SelectedProject.ProjectID,
        }),
      }),
    });

    if (resObj.response === null) {
      setStoreAuditToolResponse(resObj.exception);
    } else {
      setStoreAuditToolResponse(resObj.response);
    }
  };

  const handleClick_Back = () => {
    props.setPage("menu");
  };

  const getIssueList = async () => {
    const resObj = await runFetch(`${spURL}Handheld_StoreAuditTool_DDLIssue_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          ProjectID: props.SelectedProject.ProjectID,
        }),
      }),
    });

    if (resObj.response === null) {
      setStoreAuditToolResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setIssueList(
          spResponse.Table.map((item) => ({
            IssueID: item.ID,
            IssueSubject: item.IssueSubject,
            IssueDescription: item.IssueDescription,
            AssignedTo: item.AssignedTo,
            Resolved: item.Resolved,
            DateCreated: item["Date Created"],
            DateResolved: item["Date Resolved"],
            ResolveComment: item["Resolved Comment"],
            IsDue: item.IsDue,
          }))
        );
      } else {
        setIssueList([]);
      }
    }
  };

  useEffect(() => {
    getIssueList();
  }, []);

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div className="stocktake-create-input-row">
          <p>
            <strong>Store</strong>
          </p>
          <p>{props.From.StoreName}</p>
        </div>
        <div className="weborders-sohonly">
          <div className="weborders-printweblabels-container">
            <input
              type="checkbox"
              id="weborders-printweblabels"
              ref={refOpenOnly}
              name="weborders-printweblabels"
              defaultChecked
              onClick={handleClick_OpenOnly}
            />
            <label
              className="no-highlight"
              htmlFor="weborders-printweblabels"
            >
              Open issues only
            </label>
          </div>
        </div>
        <table className="itemdetails-table">
          <thead>
            <tr>
              <th>Subject</th>
              <th>Assigned</th>
            </tr>
          </thead>
          <tbody>
            {IssueList.filter((issue) => {
              if (IsOpenOnlyChecked) {
                return issue.Resolved !== 1;
              }
              return true;
            })
              .sort((a, b) => a.Resolved - b.Resolved)
              .map((issue, index) => (
                <tr
                  key={index}
                  issueid={issue.IssueID}
                  resolved={issue.Resolved}
                  className={`btnHover ${
                    issue.Resolved === 1
                      ? "background-lightgreen"
                      : issue.IsDue === 1
                      ? "background-salmon"
                      : ""
                  }`}
                  onClick={() => {
                    props.setSelectedIssueID(issue.IssueID);
                    props.setPage(
                      `issue-${issue.Resolved === 1 ? "view" : "edit"}${
                        is.mobile() ? "-mobile" : ""
                      }`
                    );
                  }}
                >
                  <td>{issue.IssueSubject}</td>
                  <td>{issue.AssignedTo}</td>
                </tr>
              ))}
          </tbody>
        </table>
        <p className="error-message">{StoreAuditToolResponse}</p>
      </div>

      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={green}
          onClick={handleClick_Back}
        />
        <FooterButton
          button="Home"
          colour={green}
          onClick={props.handleClick_Home}
        />
        {props.Access.StoreAuditToolAdmin && (
          <FooterButton
            button="Create"
            colour={green}
            onClick={handleClick_Create}
          />
        )}
        <FooterButton
          button="Print"
          colour={green}
          onClick={handleClick_Print}
        />
      </div>
    </div>
  );
};
