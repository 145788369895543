import React, { useEffect, useState } from "react";
import { Menu } from "./Menu";
import { Quotes } from "./Quotes";
import { Quote } from "./Quote";
import { getAccessData } from "../../App";
import store from "../../store";
import { Invoices } from "./Invoices";
import { Invoice } from "./Invoice";

export const NonStockInvoiceBody = () => {
  const [page, setPage] = useState("menu");
  const [userid, ,] = store.useState("userid");

  const [SelectedQuoteID, setSelectedQuoteID] = useState(0);
  const [SelectedInvoiceID, setSelectedInvoiceID] = useState(0);

  const [Access, setAccess] = useState({});

  useEffect(() => {
    async function getAccess() {
      setAccess(await getAccessData(userid));
    }
    getAccess();
  }, [page]);

  const menuPage = (
    <Menu
      page={page}
      setPage={setPage}
    />
  );

  const quotesPage = (
    <Quotes
      Access={Access}
      page={page}
      setPage={setPage}
      SelectedQuoteID={SelectedQuoteID}
      setSelectedQuoteID={setSelectedQuoteID}
    />
  );

  const quoteCreatePage = (
    <Quote
      Access={Access}
      page={page}
      setPage={setPage}
      SelectedQuoteID={SelectedQuoteID}
      setSelectedQuoteID={setSelectedQuoteID}
      Type="CREATE"
    />
  );

  const quoteEditPage = (
    <Quote
      Access={Access}
      page={page}
      setPage={setPage}
      SelectedQuoteID={SelectedQuoteID}
      setSelectedQuoteID={setSelectedQuoteID}
      Type="EDIT"
    />
  );

  const quoteViewPage = (
    <Quote
      Access={Access}
      page={page}
      setPage={setPage}
      SelectedQuoteID={SelectedQuoteID}
      setSelectedQuoteID={setSelectedQuoteID}
      Type="VIEW"
    />
  );

  const invoicesPage = (
    <Invoices
      page={page}
      setPage={setPage}
      SelectedInvoiceID={SelectedInvoiceID}
      setSelectedInvoiceID={setSelectedInvoiceID}
    />
  );

  const invoiceCreatePage = (
    <Invoice
      page={page}
      setPage={setPage}
      SelectedInvoiceID={SelectedInvoiceID}
      setSelectedInvoiceID={setSelectedInvoiceID}
      Type="CREATE"
    />
  );

  const invoiceEditPage = (
    <Invoice
      page={page}
      setPage={setPage}
      SelectedInvoiceID={SelectedInvoiceID}
      setSelectedInvoiceID={setSelectedInvoiceID}
      Type="EDIT"
    />
  );

  const invoiceViewPage = (
    <Invoice
      page={page}
      setPage={setPage}
      SelectedInvoiceID={SelectedInvoiceID}
      setSelectedInvoiceID={setSelectedInvoiceID}
      Type="VIEW"
    />
  );

  const getCurrentPage = (page) => {
    switch (page) {
      case "menu":
        return menuPage;
      case "quotes":
        return quotesPage;
      case "quote-create":
        return quoteCreatePage;
      case "quote-edit":
        return quoteEditPage;
      case "quote-view":
        return quoteViewPage;
      case "invoices":
        return invoicesPage;
      case "invoice-create":
        return invoiceCreatePage;
      case "invoice-edit":
        return invoiceEditPage;
      case "invoice-view":
        return invoiceViewPage;
      default:
        break;
    }
  };

  return getCurrentPage(page);
};
