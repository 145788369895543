import { BlobServiceClient } from "@azure/storage-blob";
import React, { useState, useEffect, useRef } from "react";
import { FileUploader } from "react-drag-drop-files";
import { Link } from "react-router-dom";
import { green, spURL, spURL_NoInputJSON, store } from "../../App";
import { FooterButton, MenuButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";
import imageCompression from "browser-image-compression";
import { trackPromise } from "react-promise-tracker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PopupCameraScreen } from "./PopupCameraScreen";

export const CreateIssueMobile = (props) => {
  const [StoreAuditToolResponse, setStoreAuditToolResponse] = useState("");

  const blobServiceClient = new BlobServiceClient(
    `https://tmportalimages.blob.core.windows.net/?sv=2022-11-02&ss=bfqt&srt=co&sp=rwdlacupiytfx&se=2050-12-31T13:00:00Z&st=2024-07-10T00:26:54Z&spr=https&sig=ahho9vjnyCei7eSyP5PBNmT3ppPfQajEw10jdi4qD1A%3D`
  ); // create a blobServiceClient
  const containerClient = blobServiceClient.getContainerClient("handheldstoreaudittoolimages"); // create a containerClient

  const emptyUploadedImageFile = {
    blob: "",
  };
  const [UploadedImageFile, setUploadedImageFile] = useState(emptyUploadedImageFile);

  const [showCamera, setShowCamera] = useState(false);

  const refSubject = useRef();
  const refDescription = useRef();
  const refAssignedTo = useRef();
  const refDueDate = useRef();

  const handleClick_Back = () => {
    props.setStoreAuditToolResponse("");
    props.setSelectedIssueID("");
    props.setPage("issue-list");
  };

  const handleClick_Save = async () => {
    const blobURL = `https://tmportalimages.blob.core.windows.net/handheldstoreaudittoolimages/${encodeURIComponent(
      `${props.SelectedProject.ProjectID}_${refSubject.current.value}`
    )}_sample.jpg`;

    const resObj = await trackPromise(
      runFetch(`${spURL}Handheld_StoreAuditTool_Issue_Create`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            ProjectID: props.SelectedProject.ProjectID,
            IssueSubject: refSubject.current.value,
            IssueDescription: refDescription.current.value,
            AssignedTo: refAssignedTo.current.value,
            IssueImageURL: blobURL,
            DueDate: refDueDate.current.value,
          }),
        }),
      })
    );

    if (resObj.response === null) {
      setStoreAuditToolResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        if (UploadedImageFile.blob !== "") {
          const blobClient = containerClient.getBlockBlobClient(
            `${props.SelectedProject.ProjectID}_${refSubject.current.value}_sample.jpg`
          );

          trackPromise(blobClient.uploadData(UploadedImageFile.blob))
            .then(async () => {
              props.setPage("issue-list");
            })
            .catch((err) => {
              setStoreAuditToolResponse(`ERROR!: Image upload error. ${err}`);
            });
        }
      }
    }
  };

  const processSnappedImage = async (imageString) => {
    await trackPromise(
      fetch(imageString)
        .then((data) => data.blob())
        .then((imgBlob) => {
          setUploadedImageFile({ blob: imgBlob });
        })
    );
  };

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div className="stocktake-create-input-row">
          <p>
            <strong>Subject:</strong>
          </p>
          <input
            type="text"
            ref={refSubject}
          />
        </div>
        <div className="stocktake-create-input-row">
          <p>
            <strong>Description:</strong>
          </p>
          <textarea
            ref={refDescription}
            rows="10"
            placeholder="Enter issue description here..."
          ></textarea>
        </div>
        <div className="stocktake-create-input-row">
          <p>
            <strong>Assigned to:</strong>
          </p>
          <input
            type="text"
            ref={refAssignedTo}
          />
        </div>
        <div className="stocktake-create-input-row">
          <p>
            <strong>Due:</strong>
          </p>
          <input
            type="date"
            ref={refDueDate}
          />
        </div>
        <div className="blockedorders-option-row">
          <div className="storeaudittool-fileupload-container">
            {UploadedImageFile.blob === "" ? (
              <MenuButton
                className="no-highlight"
                button="UP"
                colour={green}
                title="Upload Photo"
                onClick={() => setShowCamera(true)}
              />
            ) : (
              <>
                <img
                  className="storeaudittool-image btnHover"
                  src={URL.createObjectURL(UploadedImageFile.blob)}
                  onClick={() => setShowCamera(true)}
                />
                <FontAwesomeIcon
                  className="storeaudittool-deleteimage-icon btnHover"
                  icon="fas fa-times"
                  onClick={() => {
                    setUploadedImageFile(emptyUploadedImageFile);
                  }}
                />
              </>
            )}
          </div>
        </div>

        <p className="error-message">{StoreAuditToolResponse}</p>

        <PopupCameraScreen
          show={showCamera}
          setShow={setShowCamera}
          processSnappedImage={processSnappedImage}
        />
      </div>

      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={green}
          onClick={handleClick_Back}
        />
        <FooterButton
          button="Home"
          colour={green}
          onClick={props.handleClick_Home}
        />
        <FooterButton
          button="Create"
          colour={green}
          onClick={handleClick_Save}
        />
      </div>
    </div>
  );
};
