import { BlobServiceClient } from "@azure/storage-blob";
import React, { useState, useEffect, useRef } from "react";
import { FileUploader } from "react-drag-drop-files";
import { Link } from "react-router-dom";
import { green, spURL, spURL_NoInputJSON, store } from "../../App";
import { FooterButton, MenuButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";
import imageCompression from "browser-image-compression";
import { trackPromise } from "react-promise-tracker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const CreateIssue = (props) => {
  const [StoreAuditToolResponse, setStoreAuditToolResponse] = useState("");

  const blobServiceClient = new BlobServiceClient(
    `https://tmportalimages.blob.core.windows.net/?sv=2022-11-02&ss=bfqt&srt=co&sp=rwdlacupiytfx&se=2050-12-31T13:00:00Z&st=2024-07-10T00:26:54Z&spr=https&sig=ahho9vjnyCei7eSyP5PBNmT3ppPfQajEw10jdi4qD1A%3D`
  ); // create a blobServiceClient
  const containerClient = blobServiceClient.getContainerClient("handheldstoreaudittoolimages"); // create a containerClient

  const emptyUploadedImageFile = {
    name: "",
    blob: "",
  };
  const [UploadedImageFile, setUploadedImageFile] = useState(emptyUploadedImageFile);

  const refSubject = useRef();
  const refDescription = useRef();
  const refAssignedTo = useRef();
  const refDueDate = useRef();

  const handleClick_Back = () => {
    props.setStoreAuditToolResponse("");
    props.setSelectedIssueID("");
    props.setPage("issue-list");
  };

  const handleClick_Save = async () => {
    const blobURL = `https://tmportalimages.blob.core.windows.net/handheldstoreaudittoolimages/${encodeURIComponent(
      UploadedImageFile.name
    )}`;

    if (UploadedImageFile.blob !== "") {
      const blobClient = containerClient.getBlockBlobClient(blobURL);

      trackPromise(blobClient.uploadData(UploadedImageFile.blob))
        .then(async () => {
          await createIssue(blobURL);
        })
        .catch((err) => {
          setStoreAuditToolResponse(`ERROR!: Image upload error. ${err}`);
        });
    } else {
      await createIssue(blobURL);
    }
  };

  const createIssue = async (blobURL) => {
    const resObj = await trackPromise(
      runFetch(`${spURL}Handheld_StoreAuditTool_Issue_Create`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            ProjectID: props.SelectedProject.ProjectID,
            IssueSubject: refSubject.current.value,
            IssueDescription: refDescription.current.value,
            AssignedTo: refAssignedTo.current.value,
            IssueImageURL: blobURL,
            DueDate: refDueDate.current.value,
          }),
        }),
      })
    );

    if (resObj.response === null) {
      setStoreAuditToolResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        props.setPage("issue-list");
      }
    }
  };

  const handleChange_FileUploader = async (uploadedFile) => {
    // we assume only one photo per issue for now
    if (["image/jpeg", "image/png", "image/webp"].includes(uploadedFile.type)) {
      await trackPromise(
        compressFile(uploadedFile).then(async (compressedFile) => {
          setUploadedImageFile({
            name: compressedFile.name,
            blob: compressedFile,
          });
        })
      );
    } else {
      setUploadedImageFile(emptyUploadedImageFile);
      setStoreAuditToolResponse("ERROR!: File type not supported. (.png, .jpg, .jpeg, .webp)");
    }
  };

  const compressFile = (file) => {
    return new Promise(async function (resolve, reject) {
      let compressedFile = await imageCompression(file, {
        maxSizeMB: 2, // max image size = 200kb
        maxWidthOrHeight: 300,
        useWebWorker: true,
      });

      // let compressedFile = file;
      resolve(compressedFile);
    });
  };

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div className="stocktake-create-input-row">
          <p>
            <strong>Subject:</strong>
          </p>
          <input
            type="text"
            ref={refSubject}
          />
        </div>
        <div className="stocktake-create-input-row">
          <p>
            <strong>Description:</strong>
          </p>
          <textarea
            ref={refDescription}
            rows="10"
            placeholder="Enter issue description here..."
          ></textarea>
        </div>
        <div className="stocktake-create-input-row">
          <p>
            <strong>Assigned to:</strong>
          </p>
          <input
            type="text"
            ref={refAssignedTo}
          />
        </div>
        <div className="stocktake-create-input-row">
          <p>
            <strong>Due:</strong>
          </p>
          <input
            type="date"
            ref={refDueDate}
          />
        </div>
        <div className="blockedorders-option-row">
          <div className="storeaudittool-fileupload-container">
            {UploadedImageFile.blob === "" ? (
              <FileUploader
                multiple={false}
                handleChange={handleChange_FileUploader}
                types={["png", "jpg", "jpeg", "webp"]}
                children={
                  <MenuButton
                    className="no-highlight"
                    button="UP"
                    colour={green}
                    title="Upload Photo"
                  />
                }
              />
            ) : (
              <>
                <FileUploader
                  multiple={false}
                  handleChange={handleChange_FileUploader}
                  types={["png", "jpg", "jpeg", "webp"]}
                  children={
                    <img
                      className="storeaudittool-image btnHover"
                      src={URL.createObjectURL(UploadedImageFile.blob)}
                    />
                  }
                />
                <FontAwesomeIcon
                  className="storeaudittool-deleteimage-icon btnHover"
                  icon="fas fa-times"
                  onClick={() => {
                    setUploadedImageFile(emptyUploadedImageFile);
                  }}
                />
              </>
            )}
          </div>
        </div>

        <p className="error-message">{StoreAuditToolResponse}</p>
      </div>

      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={green}
          onClick={handleClick_Back}
        />
        <FooterButton
          button="Home"
          colour={green}
          onClick={props.handleClick_Home}
        />
        <FooterButton
          button="Create"
          colour={green}
          onClick={handleClick_Save}
        />
      </div>
    </div>
  );
};
