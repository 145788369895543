import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import { purple, green, spURL_NoInputJSON, spURL } from "../../App";
import { FooterButton, MenuButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";
import { PopupCreateProject } from "./PopupCreateProject";

export const Menu = (props) => {
  const [StoreAuditToolResponse, setStoreAuditToolResponse] = useState(
    props.StoreAuditToolResponse
  );
  const [ProjectList, setProjectList] = useState([]);
  const [showPopupCreateProject, setShowPopupCreateProject] = useState(false);

  const handleClick_Create = () => {
    setStoreAuditToolResponse("");
    setShowPopupCreateProject(true);
  };

  const getProjectList = async () => {
    const resObj = await runFetch(`${spURL}Handheld_StoreAuditTool_DDLProjects_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: props.From.StoreNo,
        }),
      }),
    });

    if (resObj.response === null) {
      setStoreAuditToolResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setProjectList(spResponse.Table);
      } else {
        setProjectList([]);
      }
    }
  };

  useEffect(() => {
    getProjectList();
  }, [props.From]);

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div className="stocktake-create-input-row">
          <p>
            <strong>Store</strong>
          </p>
          <p>{props.From.StoreName}</p>
        </div>
        <table className="itemdetails-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Project</th>
            </tr>
          </thead>
          <tbody>
            {ProjectList.map((project, index) => (
              <tr
                key={index}
                className="btnHover"
                onClick={() => {
                  props.setSelectedProject({
                    ProjectID: project.ID,
                    ProjectDescription: project.ProjectDescription,
                  });
                  props.setPage(`issue-list`);
                }}
              >
                <td>{project.ID}</td>
                <td>{project.ProjectDescription}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <p className="error-message">{StoreAuditToolResponse}</p>

        <PopupCreateProject
          show={showPopupCreateProject}
          setShow={setShowPopupCreateProject}
          From={props.From}
          setPage={props.setPage}
          setSelectedProject={props.setSelectedProject}
        />
      </div>

      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={green}
          onClick={props.handleClick_Home}
        />
        <FooterButton
          button="Home"
          colour={green}
          onClick={props.handleClick_Home}
        />
        {props.Access.StoreAuditToolAdmin && (
          <FooterButton
            button="Create"
            colour={green}
            onClick={handleClick_Create}
          />
        )}
      </div>
    </div>
  );
};
