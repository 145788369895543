import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";

import {
  spURL,
  spURL_NoInputJSON,
  spURL_Unallocated_WebOrders,
  spURL_LocalPOS,
  spURL_LocalLabel,
} from "../../App";
import { runFetch, runLabelFetch } from "../../functions/RunFetch";
import { handleChange_Input } from "../../functions/HandleChangeInput";
import { doPrintShelfLabel } from "../../functions/ShelfLabelUtils";
import store from "../../store";

export const PopupPriceChangeShelfPrinter = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");

  const [PopupResponse, setPopupResponse] = useState("");
  const [ddlLabelType, setDDLLabelType] = useState([]);

  const [SelectedLabelType, setSelectedLabelType] = useState("");
  const [Quantity, setQuantity] = useState("1");

  const handleClose = () => {
    setPopupResponse("");
    setDDLLabelType([]);
    setSelectedLabelType("SHELF");
    setQuantity("1");
    props.setShow(false);
  };

  const handleShow = async () => {
    const res = await runFetch(`${spURL_LocalPOS}Handheld_Label_LabelType_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          ItemNo: props.ItemNo,
          From: props.PriceChangesBatchDetails.Mode,
        }),
      }),
    });
    const resObj = JSON.parse(res);
    if (resObj.status === "ERROR!") {
      setPopupResponse(resObj.exception);
    } else if (resObj.status === "OK!") {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setDDLLabelType(spResponse.Table.map((item) => item.LabelType));
        setSelectedLabelType(spResponse.Table[0].LabelType);
      } else {
        setDDLLabelType([]);
      }
    }
  };

  const handleClick_Ok = async () => {
    setPopupResponse("");
    if (
      props.PriceChangesBatchDetails.Batch === "" ||
      SelectedLabelType === "" ||
      (SelectedLabelType === "SHELF" && props.PriceChangesBatchDetails.Printer === "") ||
      Quantity === "0"
    ) {
      setPopupResponse("ERROR!: Invalid input.");
    } else {
      const res =
        SelectedLabelType === "SHELF" && props.PriceChangesBatchDetails.IsPrintQuickShelfChecked
          ? await runFetch(`${spURL_LocalPOS}Handheld_Label_Add_QuickLabel`, {
              method: "POST",
              body: new URLSearchParams({
                inputJSON: JSON.stringify({
                  StoreNo: storeno,
                  ShelfPrinter: props.PriceChangesBatchDetails.Printer,
                  Override: 1,
                  Barcode: props.ItemNo,
                }),
              }),
            })
          : await runFetch(`${spURL_LocalPOS}Handheld_Label_Add_Item`, {
              method: "POST",
              body: new URLSearchParams({
                inputJSON: JSON.stringify({
                  StoreNo: storeno,
                  BatchID: props.PriceChangesBatchDetails.Batch,
                  ItemNo: props.ItemNo,
                  LabelType: SelectedLabelType,
                  Quantity: Quantity,
                  From: props.PriceChangesBatchDetails.Mode,
                }),
              }),
            });

      const resObj = JSON.parse(res);

      if (resObj.status === "ERROR!") {
        setPopupResponse(resObj.exception);
      } else if (resObj.status === "OK!") {
        let spResponse = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
          if (
            SelectedLabelType === "SHELF" &&
            props.PriceChangesBatchDetails.IsPrintQuickShelfChecked
          ) {
            await doPrintShelfLabel(
              props.PriceChangesBatchDetails.Printer,
              props.ItemNo,
              Quantity,
              props.PriceChangesBatchDetails.Mode
            );
          }
          setPopupResponse(spResponse.Table[0].OutputString);
        }
      }
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        onShow={handleShow}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <h4>
              <strong>Print Labels</strong>
            </h4>
            <br />
            <div className="stocktake-create-input-row">
              <p>
                <strong>Batch</strong>
              </p>
              <p>{props.PriceChangesBatchDetails.Batch}</p>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Printer</strong>
              </p>
              <p>{props.PriceChangesBatchDetails.Printer}</p>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Label Type</strong>
              </p>
              <select
                name="selectlabeltype"
                id="labels-pricechange-labeltype"
                onChange={(e) => {
                  handleChange_Input(e, setSelectedLabelType);
                }}
              >
                {ddlLabelType.map((item, index) => (
                  <option
                    key={index}
                    value={item}
                  >
                    {item}
                  </option>
                ))}
              </select>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Quantity</strong>
              </p>
              <input
                type="text"
                id="labels-pricechange-qty"
                defaultValue="1"
                onChange={(e) => {
                  handleChange_Input(e, setQuantity);
                }}
              />
            </div>
            <br />
            <p className="error-message">{PopupResponse}</p>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              Close
            </button>
            <button
              className="popup-button"
              onClick={handleClick_Ok}
            >
              Ok
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
