import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { green, spURL } from "../../App";
import { ContentButton, FooterButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";

export const Manager = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");
  const [userid, setUserID] = store.useState("userid");

  const [WebOrdersResponse, setWebOrdersResponse] = useState(props.MenuResponse);

  const [OrderTable, setOrderTable] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState([]);

  const [PickFilter, setPickFilter] = useState("NEW");

  const pageLoad = async (_pickfilter) => {
    setSelectedOrder([]);

    const resObj = await runFetch(`${spURL}Handheld_CustomerOrder_Manager_Order_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: storeno,
          PickFilter: _pickfilter,
        }),
      }),
    });

    if (resObj.response === null) {
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setOrderTable(
          spResponse.Table.map((item) => {
            let _background = "";

            switch (item.OrderStatus) {
              case "NEW":
                _background = "white";
                break;
              case "ASSIGNED":
                _background = "yellow";
                break;
              case "COMPLETED":
                _background = "lightgreen";
                break;
              case "VERIFIED":
                _background = "aquamarine";
                break;
              case "ISSUES":
                _background = "orange";
                break;
              case "OOS":
                _background = "salmon";
                break;
              default:
                _background = "hotpink";
                break;
            }

            return {
              OrderID: item.id,
              PickID: item.PickID,
              Categories: item.CategoriesValue,
              Status: item.OrderStatus,
              AssignedStaff: item.staffname,
              Background: `btnHover background-${_background}`,
            };
          })
        );
      } else {
        setOrderTable([]);
      }
    }
  };

  useEffect(() => {
    pageLoad(PickFilter);
  }, []);

  const handleClick_Back = () => {
    props.setPage("menu");
  };

  const handleClick_Unassign = async () => {
    setWebOrdersResponse("");

    if (selectedOrder.length === 0) {
      setWebOrdersResponse("Please select an order to continue.");
    } else {
      let order_type = "";
      switch (PickFilter) {
        case "VERIFYING":
          order_type = "Verify";
          break;
        case "ALLOCATED":
          order_type = "Pick_Multiple";
          break;
        default:
          break;
      }
      const resObj = await runFetch(`${spURL}Handheld_CustomerOrder_${order_type}_Orders_Commit`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify(
            selectedOrder.map((item) => ({
              OrderID: item.OrderID,
              PickID: item.PickID,
              UserID: userid,
              StoreNo: storeno,
              Status: "Unassign",
            }))
          ),
        }),
      });

      if (resObj.exception !== "") {
        setWebOrdersResponse(resObj.exception);
      } else {
        let spResponse = JSON.parse(resObj.response);

        setWebOrdersResponse("OK!: Orders unassigned.");
        await pageLoad(PickFilter);
      }
    }
  };

  const handleClick_PickFilter = (_filter) => {
    setWebOrdersResponse("");
    setOrderTable("");
    setPickFilter(_filter);
    pageLoad(_filter);
  };

  return (
    <div className="weborders-page-container">
      <div className="weborders-top-row">
        <div className="weborders-manager-option-row">
          <ContentButton
            button=""
            colour="white"
            id="weborders-pickfilter-new"
            onClick={() => {
              handleClick_PickFilter("NEW");
            }}
          />
          <ContentButton
            button=""
            colour="black"
            id="weborders-pickfilter-allocated"
            onClick={() => handleClick_PickFilter("ALLOCATED")}
          />
          <ContentButton
            button=""
            colour="lightgreen"
            id="weborders-pickfilter-allocated"
            onClick={() => handleClick_PickFilter("VERIFYING")}
          />
        </div>
        <p>
          <strong>{PickFilter} Orders Only</strong>
        </p>
        <p>
          <strong>Total: </strong>
          {OrderTable.length}
        </p>
        <p className="error-message">{WebOrdersResponse}</p>
      </div>
      <div className="handheld-body-container">
        <div>
          {OrderTable.length === 0 ? (
            <p className="error-message">Currently no {PickFilter} orders.</p>
          ) : (
            <>
              <table
                className="itemdetails-table no-highlight"
                id="weborders-ordertable"
              >
                <thead>
                  <tr>
                    <th>OrderID</th>
                    <th>PickID</th>
                    {["ALLOCATED", "VERIFYING"].includes(PickFilter) ? (
                      <th>Assigned Staff</th>
                    ) : (
                      <th>Categories</th>
                    )}
                  </tr>
                </thead>
                <tbody id="weborders-ordertable-rows">
                  {["ALLOCATED", "VERIFYING"].includes(PickFilter)
                    ? OrderTable.map((item, index) => (
                        <tr
                          key={index}
                          className={
                            selectedOrder
                              .map((order) => `${order.OrderID}-${order.PickID}`)
                              .includes(`${item.OrderID}-${item.PickID}`)
                              ? "btnHover background-lightblue"
                              : item.Background
                          }
                          background={item.Background}
                          orderid={item.OrderID}
                          pickid={item.PickID}
                          onClick={() => {
                            setWebOrdersResponse("");

                            if (
                              selectedOrder
                                .map((order) => `${order.OrderID}-${order.PickID}`)
                                .includes(`${item.OrderID}-${item.PickID}`)
                            ) {
                              setSelectedOrder(
                                selectedOrder.filter(
                                  (order) =>
                                    `${order.OrderID}-${order.PickID}` !==
                                    `${item.OrderID}-${item.PickID}`
                                )
                              );
                            } else {
                              setSelectedOrder((values) => [
                                ...values,
                                {
                                  OrderID: item.OrderID,
                                  PickID: item.PickID,
                                },
                              ]);
                            }
                          }}
                        >
                          <td>{item.OrderID}</td>
                          <td>{item.PickID}</td>
                          <td>{item.AssignedStaff}</td>
                        </tr>
                      ))
                    : OrderTable.map((item, index) => (
                        <tr
                          key={index}
                          background={item.Background}
                          orderid={item.OrderID}
                          pickid={item.PickID}
                        >
                          <td>{item.OrderID}</td>
                          <td>{item.PickID}</td>
                          <td dangerouslySetInnerHTML={{ __html: item.Categories }}></td>
                        </tr>
                      ))}
                </tbody>
              </table>
              <br />
            </>
          )}
        </div>
      </div>
      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={green}
          onClick={handleClick_Back}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={green}
          />
        </Link>
        {["ALLOCATED", "VERIFYING"].includes(PickFilter) && (
          <FooterButton
            button="UnAll"
            colour={green}
            onClick={handleClick_Unassign}
          />
        )}
      </div>
    </div>
  );
};
